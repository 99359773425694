import Head from 'next/head';
import { Landing } from '@scenes/Landing';

export default function LandingPage() {
  return (
    <>
      <Head>
        <title>
          MyEmailAssistant - Simplify Your RoutineEmail Tasks with AI.
        </title>
        <meta
          name="description"
          content="We strive to help people make the most of their time by automating routine tasks and simplifying their workload with AI."
        />
      </Head>
      <Landing />
    </>
  );
}
