import { Layout } from '@components/Layout';
import { ToastContainer } from 'react-toastify';
import { EmailList } from './components/EmailList';
import { Faqs } from './components/Faqs';
import { Hero } from './components/Hero';
import { Pricing } from './components/Pricing';
import { PrimaryFeatures } from './components/PrimaryFeatures';

export const Landing = () => {
  return (
    <Layout>
      <Hero />
      <PrimaryFeatures />
      <EmailList />
      <Pricing />
      <Faqs />
      <ToastContainer />
    </Layout>
  );
};
