import { Button } from '@components/Button';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  color: 'slate' | 'white';
};

export const JoinWaitListForm = ({ color }: Props) => {
  const [email, setEmail] = useState<string>('');
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Form the request for sending data to the server.
    const options = {
      // The method is POST because we are sending data.
      method: 'POST',
      // Tell the server we're sending JSON.
      headers: {
        'Content-Type': 'application/json',
      },
      // Body of the request is the JSON data we created above.
      body: JSON.stringify({
        email,
      }),
    };

    // Send the form data to our forms API on Vercel and get a response.
    const response = await fetch('/api/mail', options);

    if (!response.ok) {
      toast.error('Something went wrong. Please try again later.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    } else {
      setEmail('');
      toast('You are all signed up! 🎉', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mt-12 sm:mx-auto sm:flex sm:max-w-lg"
    >
      <div className="min-w-0 flex-1">
        <label htmlFor="email" className="sr-only">
          Email address
        </label>
        <input
          name="email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full block rounded-md border border-gray-200 px-5 py-1 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
          placeholder="Enter your email"
        />
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-3">
        <Button color={color}>Join Waitlist</Button>
      </div>
    </form>
  );
};
