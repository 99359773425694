import Image from 'next/image';

import { Container } from '@components/Container';
import backgroundImage from '@images/background-faqs.jpg';
import { Button } from '@components/Button';
import { JoinWaitListForm } from '@components/JoinWaitlistForm';

const faqs = [
  [
    {
      question: 'What is My Email Assistant?',
      answer:
        'My Email Assistant is a AI toolkit that automates your emails most tedious tasks: composing emails, writing responses, and responding to repeat emails',
    },
    {
      question:
        'What measures do you take to ensure the safety of my email data?',
      answer:
        "Your email data's safety is our top priority, and we use the most advanced security measures to make sure that none of it is saved, stored, or kept on our servers.",
    },
    {
      question: 'Do I have to pay for My Email Assistant?',
      answer:
        "My Email Assistant can help you streamline your email communication and save time on routine tasks. Our free plan will get you started integrating AI into your workflow and If you're interested in additional features and support, we have two paid plans - Basic for $14.99 a month and Advanced for $24.99 a month.",
    },
  ],
  [
    {
      question: 'What browsers are supported?',
      answer:
        'Currently My Email Assistant is only supported with Google Chrome and Microsoft Edge. We will bring more support to browsers through 2023. ',
    },
    {
      question: 'Can My Email Assistant write in my style of writing?',
      answer:
        'Yes, the AI email assistant uses AI technology to analyze your email thread and match your writing style as closely as possible. ',
    },
  ],
  [
    {
      question:
        'Can I customize the responses generated by the AI email assistant?',
      answer:
        'Yes, you can customize the responses generated by My Email Assistant through an iterative process. If a response is generated and needs additional changes, simply submit another text prompt to revise the email further.',
    },
    {
      question: 'Can the AI email assistant respond to all types of emails?',
      answer:
        'My Email Assistant is designed to handle a wide range of email types, including basic replies, forwards, and new email compositions.',
    },
  ],
];

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <Image
        className="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
        unoptimized
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can&apos;t find what you&apos;re looking for, email our
            support team and if you&apos;re lucky someone will get back to you.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>

        <JoinWaitListForm color="slate" />
      </Container>
    </section>
  );
}
